import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Delete';

import api from "../../api";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFeedback } from "../feedback/Service";
import { inject } from "mobx-react";
import usePortal from "../hooks/portal";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import DataTable from "../DataTable";
import Typography from "@material-ui/core/Typography";
import config from '../../config';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import UserEventsDialog from "../dialogs/UserEventsDialog";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const getLoginTime = user => {
  if (!user.lastLoginTime) return "no data";
  return moment(user.lastLoginTime).format("LLL") + " UTC";
}

const loginCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "date", title: "Date" },
];

const salesCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "description", title: "Description" },
  { name: "amount", title: "Amount" },
  { name: "amountRefunded", title: "Amount Refunded" },
  { name: "date", title: "Date" },
];

const certificatesCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "sessionName", title: "Session" },
  { name: "date", title: "Date" },
  { name: "score", title: "Score" },
];

const Users = ({ store, isFetching, user, events, portal }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [eventsDialogUserId, setEventsDialogUserId] = useState(null);
  const [logins, setLogins] = useState([]);
  const [sales, setSales] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [salesTab, setSalesTab] = useState(0);
  const [isFetchingSales, setIsFetchingSales] = useState(false);
  const [isFetchingConnectionTrackerData, setIsFetchingConnectionTrackerData] = useState(false);
  const [isFetchingSessionEvaluationData, setIsFetchingSessionEvaluationData] = useState(false);
  const [isFetchingSignUpData, setIsFetchingSignUpData] = useState(false);

  const history = useHistory();
  const { portalId, portalName, urlPrefix } = usePortal();

  const feedback = useFeedback();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length > 2)
      fetch(value);
  }

  const handleChangePassword = email => async () => {
    const password = await feedback.form({ title: "Change Password", input: { title: 'New password', type: "password", name: "password" } });
    if (!password)
      return;
    store.setIsFetching(true);
    api.changePassword({ email, password }).then(() => {
      feedback.snackbar({ text: "Password changed.", type: "success" });
      store.setIsFetching(false);
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.responseJSON?.message || "Error changing password", type: "error" });
    });
  }

  const handleToggleAdmin = user => async () => {
    store.setIsFetching(true);
    api.updateUser({ ...user, hasAccessToUserManagement: !user.hasAccessToUserManagement }).then(() => {
      feedback.snackbar({ text: "User updated", type: "success" });
      store.setIsFetching(false);
      fetch(search);
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.responseJSON?.message || "Error updating user", type: "error" });
    });
  }

  const handleOpenEvents = id => () => setEventsDialogUserId(id);

  const handleSubmit = e => {
    e.preventDefault();
    store.setIsFetching(true);
    api.addUser({ email, firstName, lastName, password, hasAccessToUserManagement: isAdmin }, portalName).then(x => {
      console.log(x);
      setEmail('');
      setFirstName('');
      setLastName('');
      setPassword('');
      store.setIsFetching(false);
      feedback.snackbar({ text: "User has been successfully added.", type: "success" });
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.responseJSON?.message || "Error adding user", type: "error" });
    });
  }

  const fetch = searchString => {
    store.setIsFetching(true);
    api.getUsers(searchString, portalId, portalName).then(({ body: data }) => {
      console.log(data);
      setUsers(data);
      store.setIsFetching(false);
    });
  }

  const handleDelete = ({ id, email }) => async e => {
    if (!await feedback.confirm({ title: "Remove user?", text: `Are you sure you want to delete ${email} from users?` }))
      return;
    api.deleteUser(id, portalName).then(x => {
      fetch(search);
      feedback.snackbar({ text: 'User has been successfully deleted.', type: "success" });
    }).catch(x => {
      feedback.snackbar({ text: x.responseJSON?.message || "Error deleting user", type: "error" });
    });
  }

  const handleGetLoginsClick = () => {
    api.getLoginsCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "logins.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  const handleGetSignUpsClick = id => () => {
    setIsFetchingSignUpData(true);
    api.getSignUpsCsv(id).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "signups.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingSignUpData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetConnectionTrackerDataClick = eventId => () => {
    setIsFetchingConnectionTrackerData(true);
    api.getEventConnectionTrackerDataCsv(eventId).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "connection_tracker_data.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingConnectionTrackerData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetSessionEvaluationDataClick = id => () => {
    setIsFetchingSessionEvaluationData(true);
    api.getSessionEvaluationDataCsv(id).then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "session_evaluation_data.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
      setIsFetchingSessionEvaluationData(false);
    }).catch(error => feedback.snackbar({ text: error.message, type: "error" }));
  }

  const handleGetSalesClick = () => {
    api.getSalesCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "sales.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  const handleGetCertificatesClick = () => {
    api.getLastCertificatesCsv().then((blob) => {
      const url = URL.createObjectURL(blob.body),
        linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = "certificates.csv";
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  useEffect(() => {
    if (!user.role)
      history.push(urlPrefix);
  }, [user]);

  useEffect(() => {
    const getSales = async days => {
      setSales([]);
      setIsFetchingSales(true);
      const sales = await api.getSales(days);
      setSales(sales.map(x => ({ ...x, date: moment(x.created).format("LLL") })));
      setIsFetchingSales(false);
    }
    const days = salesTab === 0 ? 1 : (salesTab === 1 ? 7 : 30);
    getSales(days);
  }, [salesTab])

  useEffect(() => {
    const getData = async () => {
      const logins = await api.getLogins();
      setLogins(logins.map(x => ({ ...x, date: moment(x.dateTime).format("LLL") })));
      setIsFetchingSales(true);
      const sales = await api.getSales(1);
      console.log("sales", sales);
      setSales(sales.map(x => ({ ...x, date: moment(x.created).format("LLL") })));
      setIsFetchingSales(false);
      const certificates = await api.getLastCertificates();
      console.log("certificates", certificates);
      setCertificates(certificates.map(x => ({ ...x, date: moment(x.issued).format("LLL") })));
    }
    getData();
  }, [])

  console.log("events", events?.length);

  return (
    <>
      <UserEventsDialog open={!!eventsDialogUserId} userId={eventsDialogUserId} handleClose={() => setEventsDialogUserId(null)} />
      <br />
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          placeholder="First Name"
          // margin="dense"
          size="small"
          variant="outlined"
          required
        />
        <TextField
          type="text"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          placeholder="Last Name"
          // margin="dense"
          size="small"
          variant="outlined"
          required
        />
        <TextField
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          // margin="dense"
          size="small"
          variant="outlined"
          required
          autoComplete="new-email"
        />
        <TextField
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          // margin="dense"
          size="small"
          variant="outlined"
          // required
          autoComplete="new-password"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdmin}
              onChange={e => setIsAdmin(e.target.checked)}
              color="primary"
            />
          }
          label={"Admin"}
        />
        <br />
        <Button variant="contained" color="primary" type="submit" disabled={isFetching}>Create User</Button>
      </form>
      <br />
      <br />
      <TextField
        fullWidth
        type="search"
        value={search}
        onChange={handleChange}
        name="search"
        placeholder="Search users by email..."
        margin="dense"
        size="small"
        variant="outlined"
      />
      <List component="nav" aria-labelledby="certificates-list-subheader"      >
        {users.map((user, i) => (
          <ListItem key={i}>
            <ListItemText primary={user.email} secondary={user.firstName + " " + user.lastName + ", Last login: " + getLoginTime(user) + (user.hasAccessToUserManagement ? ", admin" : "")} />
            <Button color="primary" onClick={handleChangePassword(user.email)}>Change Password</Button>
            <Button color="primary" onClick={handleOpenEvents(user.id)}>Events</Button>
            <Button color="primary" onClick={handleToggleAdmin(user)}>{user.hasAccessToUserManagement ? "Revoke Admin" : "Grant Admin"}</Button>
            <IconButton onClick={handleDelete(user)}>
              <RemoveIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <br />
      {(events.some(x => x.signUpFormMetaData) || portal.signUpFormMetaData) &&
        <>
          <Typography variant="h6" gutterBottom>Sign Up Data</Typography>
          {isFetchingSignUpData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {portal.signUpFormMetaData &&
                <ListItem button onClick={handleGetSignUpsClick(portal.id)}>
                  <ListItemText primary={"Portal - Download CSV"} />
                </ListItem>
              }
              {events.filter(x => x.signUpFormMetaData).map(event => (
                <ListItem key={event.id} button onClick={handleGetSignUpsClick(event.id)}>
                  <ListItemText primary={event.name + " - Download CSV"} />
                </ListItem>
              ))}
            </List>
          }
          <br />
        </>
      }
      <Typography variant="h6" gutterBottom>Connection Tracker Data</Typography>
      {isFetchingConnectionTrackerData ?
        <LinearProgress variant="query" /> :
        <List component="nav" aria-labelledby="events-list-subheader">
          {events.map(event => (
            <ListItem key={event.id} button onClick={handleGetConnectionTrackerDataClick(event.id)}>
              <ListItemText primary={event.name + " - Download CSV"} />
            </ListItem>
          ))}
        </List>
      }
      <br />
      {(events.some(x => x.evaluationFormMetaData) || portal.evaluationFormMetaData) &&
        <>
          <Typography variant="h6" gutterBottom>Session Evaluation Data</Typography>
          {isFetchingSessionEvaluationData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {portal.evaluationFormMetaData &&
                <ListItem button onClick={handleGetSessionEvaluationDataClick(portal.id)}>
                  <ListItemText primary={"Portal - Download CSV"} />
                </ListItem>
              }
              {events.filter(x => x.evaluationFormMetaData).map(event => (
                <ListItem key={event.id} button onClick={handleGetSessionEvaluationDataClick(event.id)}>
                  <ListItemText primary={event.name + " - Download CSV"} />
                </ListItem>
              ))}
            </List>
          }
        </>
      }
      <br />
      <Typography variant="h6" gutterBottom>Last 10 logins</Typography>
      <Button onClick={handleGetLoginsClick} variant="contained" >Download CSV</Button>
      <br />
      <br />
      <DataTable cols={loginCols} rows={logins} />
      <br />
      <br />
      <Typography variant="h6" gutterBottom>Last sales</Typography>
      <Button onClick={handleGetSalesClick} variant="contained" >Download CSV</Button>
      <br />
      <br />
      <Tabs
        value={salesTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, v) => setSalesTab(v)}
      >
        <Tab label="Today" />
        <Tab label="Last 7 days" />
        <Tab label="Last 30 days" />
      </Tabs>
      <DataTable cols={salesCols} rows={sales} />
      {isFetchingSales && <LinearProgress variant="query" />}
      {config.hasCme &&
        <>
          <br />
          <br />
          <Typography variant="h6" gutterBottom>Last 10 certificates</Typography>
          <Button onClick={handleGetCertificatesClick} variant="contained" >Download CSV</Button>
          <br />
          <br />
          <DataTable cols={certificatesCols} rows={certificates} />
        </>
      }
    </>
  );
};

Users.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
}

export default inject(({ store }, props) => ({
  isFetching: store.isFetching, store, user: store.currentUser, events: store.events, portal: store.portal,
}))(Users);
